import styled, { css, keyframes } from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';

export type ButtonFormat = 'primary' | 'secondary';

export interface ButtonProps {
  $format?: ButtonFormat;
  $disabled?: boolean;
  $loading?: boolean;
}

export const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  
  100% {
    transform: rotate(360deg);
  }
`;

function getButtonStyle({ $format }: ButtonProps) {
  if ($format === 'secondary') {
    return css`
      background-color: ${tertiaryColor(100)};
      outline: solid 2px rgba(11, 102, 75, 0.45);
      color: #0b664b;

      &:hover {
        outline: solid 3px rgba(11, 102, 75, 0.45);
      }
    `;
  } else {
    return css`
      background-color: ${primaryColor(500)};
      outline: solid 2px ${primaryColor(500)};
      color: ${tertiaryColor(100)};

      &:hover {
        outline: solid 3px ${primaryColor(200)};
      }
    `;
  }
}

export const buttonStyle = css<ButtonProps>`
  margin: 3px;
  padding: 15px 18px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  text-decoration: none;
  font-weight: ${fontWeight.standard};
  ${fontSize.standard};
  font-family: inherit;
  letter-spacing: normal;
  transition: all 0.2s linear;
  text-align: center;
  line-height: 1;
  border: none;

  ${props =>
    props.$disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `};

  ${props =>
    props.$loading &&
    css`
      opacity: 0.5;
      position: relative;
      pointer-events: none;
      color: transparent;

      &:before {
        border-radius: 50%;
        animation: ${spinnerAnimation} 1.2s linear infinite;
        content: '';
        position: absolute;
        height: 16px;
        width: 16px;
        top: calc(50% - (21px / 2));
        left: calc(50% - (19px / 2));
        border: 2px solid ${props.$format === 'secondary' ? primaryColor() : '#fff'};
        border-top-color: transparent;
      }
    `};

  ${props => getButtonStyle(props)};
`;

export const Button = styled.button<ButtonProps>`
  ${buttonStyle};
`;
