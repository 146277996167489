import React, { FC } from 'react';
import * as Styled from './Burger.styles';

interface BurgerProps {
  active: boolean;
  onClick: () => void;
  inverted?: boolean;
  transparent: boolean;
}

const Burger: FC<BurgerProps> = ({ active, onClick, inverted = false, transparent }) => (
  <Styled.BurgerContainer
    className="burger"
    onClick={onClick}
    active={active}
    inverted={inverted}
    $transparent={transparent}>
    <span className="burger" />
    <span className="burger" />
    <span className="burger" />
  </Styled.BurgerContainer>
);

export default Burger;
