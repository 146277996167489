import { Lazy, pipe } from 'fp-ts/function';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpError, HttpStatusCode, HttpTask } from '@core/http/model';

import * as TE from 'fp-ts/TaskEither';
import * as B from 'fp-ts/boolean';
import * as O from 'fp-ts/Option';
import { NextPageContext } from 'next';
import axiosInstance from '@core/http/config';
import { isServer } from '@shared/utils/next';
import { removeEmptyString } from '@shared/utils/string';
import { logSentryHttpError } from '@shared/modules/sentry/utils';

function onError<E>(error: unknown): HttpError<E> {
  const err = HttpError.fromAxiosError<E>(error as AxiosError<E>);

  if (
    err.status >= 400 &&
    ![
      HttpStatusCode.UNAUTHORIZED,
      HttpStatusCode.FORBIDDEN,
      HttpStatusCode.NOT_FOUND,
      HttpStatusCode.CONFLICT,
    ].includes(err.status)
  ) {
    logSentryHttpError(`[http] error ${err.status} on ${O.getOrElse(() => 'unknown')(err.url)} path`, err);
  }

  return err;
}

function forwardCookies(config: AxiosRequestConfig = {}, ctx?: NextPageContext): AxiosRequestConfig {
  if (isServer()) {
    return {
      ...config,
      headers: {
        ...config.headers,
        cookie: ctx?.req?.headers.cookie ?? '',
        'user-agent': ctx?.req?.headers['user-agent'] ?? 'de-sangosse-sav-front',
      },
    };
  }

  return config;
}

function transformRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>): HttpTask<R, E>;
function transformRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>, raw?: true): HttpTask<AxiosResponse<R>, E>;

function transformRequest<R, E>(
  request: Lazy<Promise<AxiosResponse<R>>>,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return pipe(
    TE.tryCatch(request, err => onError<E>(err)),
    TE.map(res => (raw ? res : res.data)),
  );
}

function get<R = unknown, E = unknown>(url: string, ctx?: NextPageContext, config?: AxiosRequestConfig): HttpTask<R, E>;
function get<R = unknown, E = unknown>(
  url: string,
  ctx: NextPageContext,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function get<R = unknown, E = unknown>(
  url: string,
  ctx?: NextPageContext,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return transformRequest(() => axiosInstance.get(url, forwardCookies(config, ctx)), raw);
}

function post<R = unknown, E = unknown>(
  url: string,
  data?: any,
  ctx?: NextPageContext,
  config?: AxiosRequestConfig,
): HttpTask<R, E>;
function post<R = unknown, E = unknown>(
  url: string,
  data: any,
  ctx: NextPageContext,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function post<R = unknown, E = unknown>(
  url: string,
  data?: any,
  ctx?: NextPageContext,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  const dataStringToNullable = pipe(
    isServer() || data instanceof FormData,
    B.fold(
      () =>
        pipe(
          O.fromNullable(data),
          O.fold(
            () => data,
            data => removeEmptyString(data),
          ),
        ),
      () => data,
    ),
  );

  return transformRequest(() => axiosInstance.post(url, dataStringToNullable, forwardCookies(config, ctx)), raw);
}

function del<R = unknown, E = unknown>(url: string, ctx?: NextPageContext, config?: AxiosRequestConfig): HttpTask<R, E>;
function del<R = unknown, E = unknown>(
  url: string,
  ctx: NextPageContext,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function del<R = unknown, E = unknown>(
  url: string,
  ctx?: NextPageContext,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return transformRequest(() => axiosInstance.delete(url, forwardCookies(config, ctx)), raw);
}

const httpService = {
  get,
  post,
  delete: del,
};

export default httpService;
