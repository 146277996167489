import { StringifiableRecord } from 'query-string';

export interface Route {
  path: string;
  page: string;
}

export type RouteParams = StringifiableRecord;
export type RouteQueries = StringifiableRecord;

export type RouteKey =
  | 'home'
  | 'contact'
  | 'contactSuccess'
  | 'pages'
  | 'search'
  | 'sign-in'
  | 'sign-in-limacapt'
  | 'sign-up'
  | 'forgot-password'
  | 'reset-password'
  | 'account'
  | 'activation'
  | 'step'
  | 'resolved'
  | 'resolvedSuccess'
  | 'failure'
  | 'failureSuccess'
  | 'ticket';

const routes: Record<RouteKey, Route> = {
  home: {
    path: '/',
    page: '/',
  },
  contact: {
    path: '/contact',
    page: '/contact',
  },
  contactSuccess: {
    path: '/contact/success',
    page: '/contact/success',
  },
  pages: {
    path: '/:uid',
    page: '/pages',
  },
  search: {
    path: '/search',
    page: '/search',
  },
  'sign-in': {
    path: '/sign-in',
    page: '/sign-in',
  },
  'sign-in-limacapt': {
    path: '/sign-in/limacapt/:token',
    page: '/sign-in/limacapt',
  },
  'sign-up': {
    path: '/sign-up',
    page: '/sign-up',
  },
  'forgot-password': {
    path: '/password-reset/forgot',
    page: '/forgot-password',
  },
  'reset-password': {
    path: '/password-reset/:token',
    page: '/reset-password',
  },
  account: {
    path: '/account',
    page: '/account',
  },
  activation: {
    path: '/activation/:token',
    page: '/activation',
  },
  step: {
    path: '/diagnostics/:diagnostic_number/steps/:step_id',
    page: '/diagnostics/steps',
  },
  resolved: {
    path: '/diagnostics/:diagnostic_number/resolved/:answer_id',
    page: '/diagnostics/resolved',
  },
  resolvedSuccess: {
    path: '/diagnostics/:diagnostic_number/resolved/:answer_id/success',
    page: '/diagnostics/success',
  },
  failure: {
    path: '/diagnostics/:diagnostic_number/failure/:answer_id',
    page: '/diagnostics/failure',
  },
  failureSuccess: {
    path: '/diagnostics/:diagnostic_number/failure/:answer_id/success',
    page: '/diagnostics/failure/success',
  },
  ticket: {
    path: '/tickets/:ticket_number',
    page: '/diagnostics/ticket',
  },
};

export default routes;
