import styled, { css, DefaultTheme, FlattenInterpolation, FontSizes, FontWeights, ThemeProps } from 'styled-components';
import { fontFamily, fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';

export interface TypographyProps {
  $level: FontSizes;
  $family?: string;
  $weight?: keyof FontWeights;
  $uppercase?: boolean;
  $inverted?: boolean;
}

export const typographyStyles: Record<FontSizes, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  h1: css`
    font-weight: ${fontWeight.bold};
    line-height: 1;
  `,
  h2: css`
    color: ${primaryColor(500)};
    font-weight: ${fontWeight.standard};
    line-height: 1.08;
  `,
  h3: css`
    color: ${tertiaryColor(700)};
    font-weight: ${fontWeight.bold};
    line-height: 1.18;
  `,
  h4: css`
    font-weight: ${fontWeight.normal};
    line-height: 1.23;
  `,
  large: css`
    font-weight: ${fontWeight.normal};
    line-height: 1.3;
  `,
  standard: css`
    font-weight: ${fontWeight.normal};
    line-height: 1.47;
  `,
  label: css`
    font-weight: ${fontWeight.normal};
    line-height: 1;
  `,
  small: css`
    font-weight: ${fontWeight.normal};
    line-height: 1.25;
  `,
  extraSmall: css`
    font-weight: ${fontWeight.normal};
    line-height: 1;
  `,
};

export const Typography = styled.p<TypographyProps>`
  ${props => typographyStyles[props.$level]};
  ${props => fontSize[props.$level]};

  ${props =>
    props.$family &&
    css`
      font-family: ${fontFamily};
    `};

  ${props =>
    props.$weight &&
    css`
      font-weight: ${fontWeight[props.$weight]};
    `};

  ${props =>
    props.$inverted &&
    css`
      color: ${tertiaryColor(100)};
    `};

  ${props =>
    props.$uppercase &&
    css`
      text-transform: uppercase;
    `};
`;
