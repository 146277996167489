import nookies from 'nookies';

import {
  InternationalizeString,
  InternationalizeStringOpt,
  Language,
  Locale,
  LOCALE_COOKIE_NAME,
} from '@shared/modules/translation/model';
import Router, { NextRouter } from 'next/router';

import { frTranslations } from '@shared/modules/translation/locales/fr';
import { enTranslations } from '@shared/modules/translation/locales/en';
import { NextPageContext } from 'next';
import { pipe } from 'fp-ts/function';

import * as O from 'fp-ts/Option';

function saveLocaleInCookie(locale: Locale) {
  nookies.set(undefined, LOCALE_COOKIE_NAME, locale, { maxAge: 25920000 });
}

export function switchLocale(locale: Locale) {
  saveLocaleInCookie(locale);

  Router.replace({ pathname: Router.pathname, query: Router.query }, Router.asPath, { locale });
}

export function getCurrentLocale(ctx: NextRouter | NextPageContext): Locale {
  return ctx.locale === 'fr' ? 'fr' : 'en';
}

export function getTranslations(locale: Locale) {
  return locale === 'fr' ? frTranslations : enTranslations;
}

export function prismicLocaleToLocale(locale: string): Locale {
  return locale === 'fr-fr' ? 'fr' : 'en';
}

export function getInternationalizeStringValue(value: InternationalizeString, locale: Locale): string {
  return pipe(
    locale,
    O.fromPredicate(locale => locale === 'en'),
    O.chainNullableK(() => value.english),
    O.getOrElse(() => value.french),
  );
}

export function getInternationalizeStringOptValue(
  value: InternationalizeStringOpt,
  locale: Locale,
): string | null | undefined {
  return pipe(
    locale,
    O.fromPredicate(locale => locale === 'en'),
    O.chainNullableK(() => value.english),
    O.getOrElse(() => value.french),
  );
}

export function getCurrentServerLanguage(ctx: NextRouter | NextPageContext): Language {
  const locale = getCurrentLocale(ctx);

  return locale === 'fr' ? Language.French : Language.English;
}
