import { pipe } from 'fp-ts/function';
import theme from '@styles/theme';
import * as R from 'fp-ts/Record';
import * as String from 'fp-ts/string';
import { DefaultTheme, Sizes, ThemeProps } from 'styled-components';

export const sizes = pipe(
  theme.sizes,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<Record<Extract<keyof Sizes, string>, (props: ThemeProps<DefaultTheme>) => string>>,
    (key, acc) => ({
      ...acc,
      [key]: (props: ThemeProps<DefaultTheme>) => `${props.theme.sizes[key]}px`,
    }),
  ),
);
