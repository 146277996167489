import { DefaultPrismicContent, PrismicDocument } from '@core/prismic/model';
import { SeoProps } from '@shared/components/seo/Seo';
import { filterEmptyStringToNullable } from '@shared/utils/string';

import * as O from 'fp-ts/Option';
import { constNull, pipe } from 'fp-ts/function';
import { HTMLProps, ReactNode } from 'react';
import { CustomLinkProps } from '@shared/components/link/CustomLink';
import { getCustomLinkPropsFromPrismicDocument } from '@core/prismic/resolver';

import * as prismicT from '@prismicio/types';
import * as prismicH from '@prismicio/helpers';

export function filterPrismicElement<T>(element: T | undefined | null): O.Option<T> {
  return pipe(
    O.fromNullable(element),
    O.filter(el => {
      if (Array.isArray(el)) {
        return el.length > 0 && prismicH.asText(el as any) !== '';
      } else if (typeof el === 'string') {
        return el !== '';
      } else if (typeof el === 'object') {
        // link_type -> fix PrismicLink
        return (
          Object.keys(el).length > 0 &&
          (el as any).link_type !== 'Any' &&
          ((el as any).link_type !== 'Media' || (el as any).url != null)
        );
      } else {
        return true;
      }
    }),
  );
}

export function renderPrismicElement<T, R extends ReactNode>(
  element: T | undefined | null,
  onSome: (element: T) => R,
  onNone: () => R | null = constNull,
): R | null {
  return pipe(filterPrismicElement(element), O.fold(onNone, onSome));
}

export function getSeoFromPrismicDocument(document: PrismicDocument<DefaultPrismicContent>): SeoProps {
  const { data } = document;

  return {
    title: filterEmptyStringToNullable(data.metaTitle),
    description: filterEmptyStringToNullable(data.metaDescription),
    image: filterEmptyStringToNullable(data.metaImage?.url),
  };
}

export function getLinkPropsFromPrismicLink(link: prismicT.LinkField): Omit<CustomLinkProps, 'ref' | 'as'> {
  return pipe(
    filterPrismicElement(link),
    O.fold<prismicT.LinkField, HTMLProps<HTMLAnchorElement>>(
      () => ({}),
      l => {
        if (l.link_type === 'Document') {
          return getCustomLinkPropsFromPrismicDocument(l as any);
        } else {
          const target = l.link_type === 'Media' ? '_blank' : (l as any).target;

          return {
            target: target,
            rel: target === '_blank' ? 'noopener noreferrer' : undefined,
            href: (l as any).url,
          };
        }
      },
    ),
  );
}
