import styled from 'styled-components';

import { fontSize, fontWeight } from '../utils';
import { typographyStyles } from '@styles/shared/Typography';

export const RichTextContainer = styled.div`
  ${fontSize.standard};

  p {
    min-height: 5px;
    margin-bottom: 10px;
  }

  h1 {
    ${typographyStyles.h1};
    margin-bottom: 10px;
    ${fontSize.h1};
  }

  h2 {
    ${typographyStyles.h2};
    margin-bottom: 10px;
    ${fontSize.h2};
  }

  h3 {
    ${typographyStyles.h3};
    margin-bottom: 10px;
    ${fontSize.h3};
  }

  h4 {
    ${typographyStyles.h4};
    margin-bottom: 10px;
    ${fontSize.h4};
  }

  strong {
    font-weight: ${fontWeight.bold};
  }

  em {
    font-style: italic;
  }

  a {
    text-transform: none;
  }

  ul {
    list-style: disc;
    padding-left: 25px;
    margin-bottom: 10px;
  }

  ol {
    list-style: decimal;
    padding-left: 25px;
    margin-bottom: 10px;
  }

  .underline {
    text-decoration: underline;
  }

  .embed {
    padding: 25px 0;

    > div {
      position: relative;
      padding-bottom: 56.25%;
      overflow: hidden;

      > iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

  .block-img {
    img {
      max-width: 100%;
    }
  }

  transition: color 0.25s linear;
`;
