import * as Sentry from '@sentry/nextjs';
import { HttpError } from '@core/http';

export function logSentryMessage(
  message: string,
  severity: Sentry.SeverityLevel = 'error',
  extras?: { [key: string]: any },
) {
  Sentry.captureMessage(message, {
    level: severity,
    extra: extras,
  });
}

export function logSentryHttpError(
  message: string,
  error: HttpError,
  severity: Sentry.SeverityLevel = 'error',
  extras?: { [key: string]: any },
) {
  logSentryMessage(message, severity, {
    ...error.toJson(),
    ...extras,
  });
}
