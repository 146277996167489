import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Router } from 'next/router';

import * as Styled from './PageLoader.styles';
import { useDebouncedCallback } from 'use-debounce';

const PageLoader: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const debouncedSetLoading = useDebouncedCallback(setLoading, 450);

  const startLoading = useCallback(() => debouncedSetLoading(true), [debouncedSetLoading]);
  const stopLoading = useCallback(() => debouncedSetLoading(false), [debouncedSetLoading]);

  useEffect(() => {
    Router.events.on('routeChangeStart', startLoading);
    Router.events.on('routeChangeComplete', stopLoading);
    Router.events.on('routeChangeError', stopLoading);

    return () => {
      Router.events.off('routeChangeStart', startLoading);
      Router.events.off('routeChangeComplete', stopLoading);
      Router.events.off('routeChangeError', stopLoading);
    };
  }, [startLoading, stopLoading]);

  return loading ? <Styled.LineLoader /> : null;
};

export default memo(PageLoader);
