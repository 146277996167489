import getConfig from 'next/config';

import * as O from 'fp-ts/Option';
import { filterEmptyStringToOption } from '@shared/utils/string';

export function getServerRuntimeConfig(): { [key: string]: string } {
  return getConfig().serverRuntimeConfig;
}

export function getPublicRuntimeConfig(): { [key: string]: string } {
  return getConfig().publicRuntimeConfig;
}

export function getServerRuntimeConfigValue(key: string): O.Option<string> {
  return filterEmptyStringToOption(getServerRuntimeConfig()[key]);
}

export function getPublicRuntimeConfigValue(key: string): O.Option<string> {
  return filterEmptyStringToOption(getPublicRuntimeConfig()[key]);
}
