import { Translations } from '@shared/modules/translation/locales';
import { useRouter } from 'next/router';
import { getCurrentLocale, getCurrentServerLanguage, getTranslations } from '@shared/modules/translation/utils';
import { Language, Locale } from '@shared/modules/translation/model';

export function useTranslations(): Translations {
  const router = useRouter();

  return getTranslations(getCurrentLocale(router));
}

export function useCurrentLocale(): Locale {
  const router = useRouter();

  return getCurrentLocale(router);
}

export function useCurrentServerLanguage(): Language {
  const router = useRouter();

  return getCurrentServerLanguage(router);
}
