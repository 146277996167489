import { NextPageContext } from 'next';
import { HttpError, HttpResult, HttpStatusCode, HttpTask } from '@core/http/model';
import { pipe } from 'fp-ts/function';

import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import routes, { RouteKey, RouteParams } from '../../routes';
import { getLink, navigateTo } from '@shared/utils/routes';

export function serverRedirect(
  { res }: NextPageContext,
  key: RouteKey,
  params?: RouteParams,
  queries?: RouteParams,
): T.Task<boolean> {
  if (res) {
    return T.fromIO(() => {
      res.writeHead(302, { Location: getLink(routes[key].path, params, queries) });
      res.end();

      return true;
    });
  } else {
    return () => navigateTo(key, params, queries);
  }
}

export function handleInitialPropsHttpError<T = unknown>(
  ctx: NextPageContext,
  err: HttpError<T>,
): T.Task<HttpError<T>> {
  const { res, asPath } = ctx;

  if (res) {
    res.statusCode = err.status;
  }

  if (HttpStatusCode.UNAUTHORIZED === err.status) {
    return pipe(
      serverRedirect(ctx, 'sign-in', undefined, { referrer: asPath }),
      T.map(() => err),
    );
  } else {
    return T.of(err);
  }
}

export function getPageInitialProps<R = unknown, E = unknown>(
  f: (ctx: NextPageContext) => HttpTask<R, E>,
): (ctx: NextPageContext) => Promise<HttpResult<R, E>> {
  return ctx =>
    pipe(
      f(ctx),
      TE.orElse(err => TE.leftTask(handleInitialPropsHttpError(ctx, err))),
    )();
}
