import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Profile, Role } from '@modules/auth/model';
import { constVoid, Lazy, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';

import * as AuthService from './service';
import { useCurrentServerLanguage } from '@shared/modules/translation/hooks';

interface AuthContextValue {
  profile: O.Option<Profile>;
  updateProfile: (profile: Profile) => void;
  requestUpdateProfile: Lazy<void>;
  logout: T.Task<void>;
}

export const AuthContext = createContext<AuthContextValue>({
  profile: O.none,
  updateProfile: constVoid,
  requestUpdateProfile: constVoid,
  logout: T.never,
});

const AUTH_LOGOUT_KEY = 'DS_LOGOUT';

interface AuthContextProviderProps {
  profile: O.Option<Profile>;
}

const AuthContextProvider: FC<PropsWithChildren<AuthContextProviderProps>> = ({
  profile: receivedProfile,
  children,
}) => {
  const [profile, setProfile] = useState<O.Option<Profile>>(receivedProfile);

  useEffect(() => {
    setProfile(receivedProfile);
  }, [receivedProfile]);

  const fetchProfile = useCallback(() => {
    pipe(
      AuthService.getProfile(),
      T.chainIOK(res => () => setProfile(O.fromEither(res))),
    )();
  }, []);

  const currentLanguage = useCurrentServerLanguage();
  const userLanguage = pipe(
    profile,
    O.map(profile => profile.language),
    O.toNullable,
  );

  // Language Sync
  useEffect(() => {
    if (userLanguage != null && userLanguage !== currentLanguage) {
      pipe(
        AuthService.updateProfileLanguage(currentLanguage),
        TE.chainIOK(
          () => () =>
            setProfile(old =>
              pipe(
                old,
                O.map(profile => ({ ...profile, language: currentLanguage })),
              ),
            ),
        ),
      )();
    }
  }, [currentLanguage, userLanguage]);

  // Logout Sync
  useEffect(() => {
    const syncLogout = (event: StorageEvent) => {
      if (event.key === AUTH_LOGOUT_KEY) {
        setProfile(O.none);
        window.location.reload();
      }
    };

    window.addEventListener('storage', syncLogout, { passive: true });

    return () => {
      window.removeEventListener('storage', syncLogout);
      localStorage.removeItem(AUTH_LOGOUT_KEY);
    };
  }, []);

  const updateProfile = useCallback((profile: Profile) => setProfile(O.some(profile)), []);

  const logout = useCallback(() => {
    return pipe(
      AuthService.logout(),
      T.chainIOK(() => () => {
        localStorage.setItem(AUTH_LOGOUT_KEY, Date.now().toString());

        setProfile(O.none);

        window.location.reload();
      }),
    )();
  }, []);

  const ctx = useMemo<AuthContextValue>(
    () => ({
      profile,
      updateProfile,
      requestUpdateProfile: fetchProfile,
      logout,
    }),
    [profile, updateProfile, fetchProfile, logout],
  );

  return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>;
};

export function useAuthContext(): AuthContextValue {
  return useContext(AuthContext);
}

export function useRole(): Role | null {
  const { profile } = useAuthContext();

  return pipe(
    profile,
    O.map(profile => profile.role),
    O.toNullable,
  );
}

export default AuthContextProvider;
