import React, { FC, HTMLProps, memo, MouseEventHandler, useMemo } from 'react';

import Link from 'next/link';

import routes, { RouteKey, RouteParams, RouteQueries } from '../../../routes';

import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import { useRouter } from 'next/router';
import { getLink, getPage } from '@shared/utils/routes';

export interface CustomLinkProps extends Omit<HTMLProps<HTMLAnchorElement>, 'as'> {
  to?: RouteKey;
  params?: RouteParams;
  queries?: RouteQueries;
  hash?: string;
  activeClassName?: string;
  strict?: boolean;
  shallow?: boolean;
  scroll?: boolean;
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
}

export interface RequiredCustomLinkProps extends CustomLinkProps {
  to: RouteKey;
}

export function isRequiredCustomLinkProps(linkProps: CustomLinkProps): linkProps is RequiredCustomLinkProps {
  return linkProps.to != null;
}

function compareLink(routerLink: string, link?: string, strict?: boolean): boolean {
  if (!link) {
    return false;
  }

  if (link === '/' || strict) {
    return routerLink.split('?')[0] === link;
  } else {
    return routerLink.includes(link);
  }
}

const CustomLink: FC<CustomLinkProps> = ({
  to,
  params,
  queries,
  hash,
  href,
  activeClassName,
  strict,
  shallow,
  scroll,
  className,
  children,
  ...linkProps
}) => {
  const router = useRouter();

  const route = pipe(
    O.fromNullable(to),
    O.chainNullableK(to => routes[to]),
  );

  const page = pipe(
    route,
    O.map(r => getPage(r.page, params, queries)),
  );

  const link = pipe(
    route,
    O.map(r => getLink(r.path, params, queries, hash)),
    O.toUndefined,
  );

  const linkClassName = useMemo(
    () =>
      pipe(
        O.fromNullable(activeClassName),
        O.filter(() => compareLink(router.asPath, link, strict)),
        O.fold(
          () => className,
          cl => `${cl} ${className ? className : ''}`,
        ),
      ),
    [activeClassName, className, router, link, strict],
  );

  const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = e => {
    if (href?.startsWith('#')) {
      e.preventDefault();

      document.querySelector(href)?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return pipe(
    page,
    O.fold(
      () => (
        <a href={href} {...linkProps} className={linkClassName} onClick={handleLinkClick}>
          {children}
        </a>
      ),
      p => (
        <Link href={p} as={link} shallow={shallow} scroll={scroll}>
          <a {...linkProps} className={linkClassName}>
            {children}
          </a>
        </Link>
      ),
    ),
  );
};

export default memo(CustomLink);
