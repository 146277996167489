import { Translations } from '@shared/modules/translation/locales/index';

export const enTranslations: Translations = {
  header: {
    login: 'Sign in',
  },
  language: {
    french: 'French',
    english: 'English',
  },
  account: {
    title: 'My profile',
    save: 'Save',
  },
  signIn: {
    title: 'Sign in',
    email: 'E-mail address',
    emailPlaceholder: 'Your e-mail address',
    password: 'Password',
    login: 'Login',
    incorrectPassword: 'Your E-mail address or password is incorrect',
    forbidden: 'Your account is disabled, for more information, please',
    forbiddenContact: 'contact us',
    forgotPassword: 'Forgot your password ?',
    newAccount: 'No account ?',
    newAccountLink: 'Create an account',
  },
  signUp: {
    title: 'Create account',
    isRetailer: 'I am a reseller',
    socialReason: 'Company name',
    serialNumber: 'Serial number',
    findSerialNumber: 'How do I find my serial number ?',
    lastName: 'Last name',
    lastNamePlaceholder: 'Your last name,',
    firstName: 'First name',
    firstNamePlaceholder: 'Your first name',
    addressFirstLine: 'Address',
    addressSecondLine: 'Complement',
    addressPostalCode: 'Postal code',
    addressCity: 'City',
    addressCountry: 'Country',
    email: 'E-mail',
    emailPlaceholder: 'Your e-mail address',
    phoneNumber: 'Phone number',
    password: 'Password',
    confirmPassword: 'Password confirmation',
    confirmPasswordPlaceholder: 'Confirm your password',
    accept: 'I have read and accept the',
    acceptAnd: 'and the ',
    cgu: 'Terms of Service',
    privacy: 'Privacy Policy',
    acceptError: 'You must accept the terms and conditions of use',
    create: 'Create account',
  },
  shared: {
    email: 'E-mail address',
    password: 'Password',
    passwordPlaceholder: 'Your password',
    confirmPassword: 'Password confirmation',
    confirmPasswordPlaceholder: 'Confirm your password',
  },
  activate: {
    title: 'Account activation',
    email: 'E-mail address',
    password: 'Password',
    passwordPlaceholder: 'Your password',
    confirmPassword: 'Password confirmation',
    confirmPasswordPlaceholder: 'Confirm your password',
    button: 'Activate',
    error: {
      conflict: 'Account already exists',
    },
  },
  resetPassword: {
    title: 'Reset password',
    button: 'Reset',
  },
  forgotPassword: {
    title: 'Forgotten password',
    email: 'E-mail address',
    emailPlaceholder: 'Your e-mail address',
    text: 'Please enter the email address associated with your account. We will send you instructions on how to change your password.',
    send: 'Send',
    cancel: 'Cancel',
    success: 'An email was sent to this address with the procedure to follow',
    error: {
      notFound: 'No user found with this email address',
    },
  },
  error: {
    message: {
      default: 'A technical error occurred',
      unauthorized: 'Incorrect email address or password',
      notFound: 'Incorrect serial number',
      conflict: 'Existing account',
      confirmPasswordError: 'Passwords do not match',
    },
    serial: {
      notFound: 'Invalid number',
      forbidden: 'The trouble ticket must be created by your retailer. Please contact your retailer.',
    },
    page: {
      seoTitle: 'Error',
      title: 'This page does not exist',
      description: 'There’s nothing here… Going back to the home page?',
      home: 'Home',
    },
  },
  success: {
    message: {
      default: 'Your information has been modified',
    },
  },
  user: {
    menu: {
      profile: 'My profile',
      logout: 'Logout',
    },
    modal: {
      title: 'Change e-mail address',
      oldEmail: 'Old e-mail address',
      email: 'New e-mail address',
      emailPlaceholder: 'Your new e-mail address',
      cancel: 'Cancel',
      save: 'Save',
    },
  },
  contact: {
    companyName: 'Company name',
    lastName: 'Last name',
    lastNamePlaceholder: 'Your last name',
    firstName: 'First name',
    firstNamePlaceholder: 'Your first name',
    email: 'E-mail address',
    emailPlaceholder: 'Your e-mail address',
    phoneNumber: 'Phone number',
    phoneNumberPlaceholder: 'Your phone number',
    subject: 'Subject',
    subjectPlaceholder: 'Subject of your request',
    message: 'Message',
    messagePlaceholder: 'Your message',
    required: 'Required fields',
    send: 'Send',
    title: 'Request sent',
    success: {
      title: 'Your message has been sent',
      subtitle:
        'We confirm that your message has been sent to our support teams. We will reply to you by email as soon as possible.',
      link: 'Home',
    },
  },
  search: {
    placeholder: 'Search a diagnostic, a product, a document...',
    title: 'Your search',
    empty: 'No result',
    homeTitle: 'Home',
  },
  diagnostics: {
    resolved: {
      title: 'Thank you for completing your online diagnosis!',
      subtitle: 'Thank you for your time !',
      label: 'Your opinion matters',
      text: 'In order to help you better, please leave us a note and a comment. The more details you provide, the better we can help you',
      comment: 'Do you have any comments to share with us ?',
      commentPlaceholder: 'Message',
      send: 'Send',
      error: {
        badRequest: 'You must put a score higher than 0.',
      },
    },
    success: {
      title: 'Comment sent',
      subtitle: 'Your note has been sent',
      text: 'Thank you for taking the time to give us this feedback, your comments are valuable',
      home: 'Home',
    },
  },
  tickets: {
    title: 'Trouble ticket',
    signIn: 'Login to send us your service request',
    subtitle: 'Which device is affected ?',
    serial: 'Please specify the serial number of the device affected by your fault',
    serialPlaceholder: 'Serial number',
    next: 'Next',
    home: 'Home',
    contact: 'Contact us',
    create: {
      title: 'Create a service ticket',
      text: 'In order to finalize the creation of your service ticket, please check your information :',
      serialNumber: 'Serial number',
      diagnostic: 'Name of the diagnosis',
      companyName: 'Company name',
      lastName: 'Name',
      lastNamePlaceholder: 'Your name',
      firstName: 'First name',
      firstNamePlaceholder: 'Your first name',
      email: 'E-mail address',
      emailPlaceholder: 'Your email address',
      phoneNumber: 'Telephone',
      phoneNumberPlaceholder: 'Your phone number',
      address: 'Address',
      addressPlaceholder: 'Your postal address',
      postalCode: 'Postal code',
      city: 'City',
      country: 'Country',
      comment: 'Comment',
      commentPlaceholder: 'Briefly summarize your trouble here',
      required: '* Required fields',
      button: 'Create a service ticket',
      remaining: 'Number of characters remaining : ',
    },
    success: {
      title: 'Your request has been submitted to our after-sales team',
      subtitle:
        'We confirm the creation of your service ticket. An e-mail containing a link to follow up your request has been sent to you.',
      link: 'Home',
    },
    detail: {
      title: 'Tracking your ticket',
      subtitle: 'Your ticket has been received by our services',
      send: 'Sent on',
      text: 'You have submitted a trouble ticket',
      serialNumber: 'Serial number :',
      status: {
        ouv: 'Open',
        matr: 'Material received',
        enco: 'Service processing in progress',
        mate: 'Material shipped',
        ferm: 'Service ticket closed',
      },
    },
  },
  select: {
    add: 'Add',
  },
};
