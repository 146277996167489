import {
  BackgroundColor,
  ComplementaryColors,
  DefaultTheme,
  PrimaryColor,
  SecondaryColor,
  TertiaryColor,
  TextColors,
  ThemeProps,
} from 'styled-components';

function getColor<C extends Record<number, string>>(
  color: C,
  defaultKey: Extract<keyof C, number>,
  key?: Extract<keyof C, number>,
): string {
  return color[key ?? defaultKey];
}

export function primaryColor(key?: Extract<keyof PrimaryColor, number>): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => getColor(theme.colors.primary, 700, key);
}

export function secondaryColor(
  key?: Extract<keyof SecondaryColor, number>,
): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => getColor(theme.colors.secondary, 500, key);
}

export function tertiaryColor(key?: Extract<keyof TertiaryColor, number>): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => getColor(theme.colors.tertiary, 700, key);
}

export function backgroundColor(
  key?: Extract<keyof BackgroundColor, number>,
): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => getColor(theme.colors.background, 100, key);
}

export function textColor(key: keyof TextColors): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => theme.colors.text[key];
}

export function complementaryColor(key: keyof ComplementaryColors): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => theme.colors.complementary[key];
}

export function warningColor(): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => theme.colors.warning;
}
