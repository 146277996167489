import styled, { css } from 'styled-components';
import { mediaMax, tertiaryColor } from '@styles/utils';

export const BurgerContainer = styled.div<{ active: boolean; inverted: boolean; $transparent: boolean }>`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  height: 18px;
  width: 27px;
  display: none;
  background: none;
  outline: none;
  border: none;
  transition: all 0.5s ease-in-out;
  cursor: pointer;

  @media screen and (${mediaMax.tablet}) {
    display: block;
  }

  > span {
    opacity: 1;
    position: absolute;
    display: block;
    right: 0;
    height: 3px;
    transition: background 0.1s linear, all 0.25s ease-in-out;

    &:nth-child(1),
    &:nth-child(2) {
      width: 100%;
      background: ${props => (props.$transparent ? tertiaryColor(100) : tertiaryColor(900))};

      &:before {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: ${props => (props.$transparent ? tertiaryColor(100) : tertiaryColor(900))};
        transition: width 0.1s ease-in-out;
      }
    }

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 9px;
    }

    &:nth-child(3) {
      top: 18px;
      width: 100%;
      background-color: ${props => (props.$transparent ? tertiaryColor(100) : tertiaryColor(900))};
    }
  }

  ${props =>
    props.active &&
    css`
      width: 25px;

      > span {
        width: 25px;

        &:nth-child(1) {
          top: 0;
          transform: rotate(45deg);
          transform-origin: left;
          background-color: ${tertiaryColor(100)};

          &:before {
            display: none;
          }
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          width: 100%;
          transform: rotate(-45deg);
          transform-origin: left;
          background-color: ${tertiaryColor(100)};
        }
      }
    `}
`;
