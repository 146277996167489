import React, { FC, useEffect, useState } from 'react';
import * as Styled from './Header.styles';
import Router from 'next/router';
import logo from '@assets/logos/logo.svg';
import inversedLogo from '@assets/logos/logo-inversed.svg';
import { LayoutPrismicContentLinks } from '@layout/model';
import { GroupField } from '@prismicio/types';
import Burger from '@shared/components/burger/Burger';
import Nav from '@layout/header/nav/Nav';
import { useAuthContext } from '@modules/auth/context';

const SCROLL_TOLERANCE = 75;

interface HeaderProps {
  links: GroupField<LayoutPrismicContentLinks>;
  headerLight?: boolean;
}

const Header: FC<HeaderProps> = ({ links, headerLight = false }) => {
  const { profile } = useAuthContext();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [transparent, setTransparent] = useState<boolean>(headerLight);

  useEffect(() => {
    setTransparent(headerLight);
  }, [headerLight]);

  const toggleMenu = () => setMenuOpen(old => !old);

  useEffect(() => {
    const handleRouteChange = () => setMenuOpen(false);
    const handleScroll = () => setTransparent(headerLight && window.scrollY < SCROLL_TOLERANCE);

    handleScroll();

    window.addEventListener('scroll', handleScroll, { passive: true });
    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [headerLight]);

  return (
    <Styled.HeaderContainer $transparent={transparent}>
      <Styled.HeaderContent>
        <Styled.HeaderLogo to="home" title="Accueil">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={transparent ? inversedLogo.src : logo.src} width={100} height={79} alt="SAV logo" loading="eager" />
        </Styled.HeaderLogo>

        <Nav links={links} show={menuOpen} profile={profile} transparent={transparent} />
        <Burger active={menuOpen} onClick={toggleMenu} transparent={transparent} />
      </Styled.HeaderContent>
    </Styled.HeaderContainer>
  );
};

export default Header;
