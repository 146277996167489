import styled, { css } from 'styled-components';
import CustomLink from '@shared/components/link/CustomLink';
import { fontFamily, fontSize, fontWeight, mediaMax, mediaMin, primaryColor, tertiaryColor } from '@styles/utils';
import { rgba } from 'polished';
import Image from 'next/image';
import disconnectIcon from '@assets/icons/disconnect.svg';
import SearchInput from '@modules/search/components/input/SearchInput';

export const NavContainer = styled.div<{ show: boolean }>`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;

  @media screen and (${mediaMax.tablet}) {
    display: none;
  }

  ${props =>
    props.show &&
    css`
      @media screen and (${mediaMax.tablet}) {
        padding: 128px 20px 80px 20px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${primaryColor(500)};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    `};
`;

export const NavLinks = styled.ul`
  height: 100%;

  @media screen and (${mediaMax.tablet}) {
    padding-bottom: 25px;
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: solid 1px ${tertiaryColor(100)};
  }
`;

export const NavMenu = styled.p`
  display: none;

  @media screen and (${mediaMax.tablet}) {
    display: block;
    ${fontSize.h1};
    font-weight: ${fontWeight.bold};
    color: ${tertiaryColor(100)};
  }
`;

export const NavLink = styled(CustomLink)<{ $transparent: boolean }>`
  padding: 30px 0;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  ${fontSize.standard};
  font-weight: ${fontWeight.normal};
  color: ${props => (props.$transparent ? tertiaryColor(100) : tertiaryColor(700))};
  margin-bottom: -3px;
  border-bottom: 3px solid ${props => (props.$transparent ? tertiaryColor(100) : '#0b664b')};
  height: 100%;
  cursor: pointer;

  &:hover {
    font-weight: ${fontWeight.semiBold};
  }

  &:not(:last-child) {
    margin-right: 36px;
  }

  &::before {
    display: block;
    content: attr(title);
    font-weight: ${fontWeight.semiBold};
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  @media screen and (${mediaMax.tablet}) {
    padding: 15px 0 0 0;
    color: ${tertiaryColor(100)};
    font-weight: ${fontWeight.normal};
    height: max-content;
    border-bottom: none;
  }
`;

export const NavRight = styled.div`
  display: flex;
  align-items: center;
`;

export const NavProfileContainer = styled.div`
  position: relative;
`;

export const NavProfile = styled.div`
  width: 39px;
  height: 39px;
  text-decoration: none;
  background-color: ${primaryColor(200)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-family: ${fontFamily};
  ${fontSize.standard};
  font-weight: ${fontWeight.semiBold};
  color: ${tertiaryColor(100)};
  cursor: pointer;

  @media screen and (${mediaMax.tablet}) {
    margin-top: 20px;
  }
`;

export const NavProfileChoice = styled.div`
  padding: 20px;
  position: absolute;
  top: calc(100% + 17px);
  right: 0;
  transform: translateX(24px);
  background-color: ${tertiaryColor(100)};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.04);
  width: 225px;

  @media screen and (${mediaMax.tablet}) {
    left: 0;
    right: auto;
    top: calc(100% + 10px);
    transform: none;
  }
`;

export const NavProfileChoiceLink = styled.p`
  margin-bottom: 8px;
  ${fontSize.standard};
  text-decoration: none;
  color: ${tertiaryColor(700)};
  cursor: pointer;
`;

export const NavProfileChoiceDivider = styled.div`
  margin: 15px 0;
  background-color: rgba(112, 112, 112, 0.18);
  height: 1px;
  width: 100%;
`;

export const NavProfileChoiceDisconnect = styled.div`
  padding-left: 22px;
  ${fontSize.small};
  color: rgba(119, 119, 116, 0.65);
  background: url(${disconnectIcon.src}) no-repeat center left;
  cursor: pointer;
`;

export const NavLogin = styled(CustomLink)<{ $transparent: boolean }>`
  display: block;
  text-decoration: none;
  color: ${props => (props.$transparent ? tertiaryColor(100) : tertiaryColor(900))};

  @media screen and (${mediaMax.tablet}) {
    padding-top: 25px;
    font-weight: ${fontWeight.standard};
    color: ${tertiaryColor(100)};
  }
`;

export const NavRightSeparator = styled.div<{ $transparent: boolean }>`
  margin: 0 20px;
  background-color: ${props => (props.$transparent ? tertiaryColor(100) : rgba(tertiaryColor(700)(props), 0.3))};
  width: 1px;
  height: 38px;

  @media screen and (${mediaMax.tablet}) {
    display: none;
  }
`;

export const NavRightLanguage = styled.div`
  margin-left: 20px;
  position: relative;

  @media screen and (${mediaMax.tablet}) {
    margin-left: 0;
    position: absolute;
    top: 73px;
    left: 20px;
  }
`;

export const NavRightLanguageLabel = styled.p<{ $transparent: boolean }>`
  cursor: pointer;
  font-size: 14px;
  font-weight: ${fontWeight.semiBold};
  color: ${props => (props.$transparent ? tertiaryColor(100) : tertiaryColor(700))};

  @media screen and (${mediaMax.tablet}) {
    color: ${tertiaryColor(100)};
  }
`;

export const NavRightLanguageChoice = styled.div`
  position: absolute;
  top: calc(100% + 17px);
  right: 0;
  transform: translateX(24px);
  background-color: ${tertiaryColor(100)};
  border-radius: 2px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);

  &:after {
    position: absolute;
    top: 0;
    right: 24px;
    transform: translateY(-100%) translateX(25%);
    border: 13px solid transparent;
    content: '';
    height: 0;
    width: 0;
    border-bottom-color: ${tertiaryColor(100)};
    margin-left: 13px;
  }

  @media screen and (${mediaMax.tablet}) {
    left: -30px;
    width: max-content;

    &:after {
      left: 0;
      transform: translateY(-100%) translateX(-12px);
    }
  }
`;

export const NavRightLanguageContent = styled.div`
  padding: 20px 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${tertiaryColor(100)};
`;

export const NavRightLanguageItem = styled.div`
  display: grid;
  grid-template-columns: 28px 1fr;
  column-gap: 12px;
  cursor: pointer;

  &:not(:last-child) {
    padding-bottom: 13px;
  }
`;

export const NavRightLanguageItemImage = styled(Image)`
  width: 28px;
  height: 23px;
`;

export const NavRightLanguageItemLabel = styled.p<{ active: boolean }>`
  ${fontSize.standard};
  font-weight: ${props => (props.active ? fontWeight.semiBold : fontWeight.normal)};
`;

export const NavMobileSearchInput = styled(SearchInput)`
  position: absolute;
  bottom: 30px;
  width: calc(100% - 40px);
  background-color: ${primaryColor(500)};

  @media screen and (${mediaMin.tablet}) {
    display: none;
  }
`;
