import { RouteKey } from '../../routes';
import { CustomLinkProps, isRequiredCustomLinkProps } from '@shared/components/link/CustomLink';
import { PrismicPageType } from '@core/prismic/model';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { getLinkFromLinkProps } from '@shared/utils/routes';
import * as prismicH from '@prismicio/helpers';
import { FilledLinkToDocumentField } from '@prismicio/types';
import { prismicLocaleToLocale } from '@shared/modules/translation/utils';

type ExternalUrlResolver = { type: 'external'; url: string };

type PrismicPageLinkResolver =
  | RouteKey
  | CustomLinkProps
  | ExternalUrlResolver
  | ((doc: Omit<FilledLinkToDocumentField, 'url'>) => RouteKey | CustomLinkProps | ExternalUrlResolver);

function isExternalUrlResolver(resolver: PrismicPageLinkResolver): resolver is ExternalUrlResolver {
  return typeof resolver === 'object' && resolver.type === 'external';
}

export const linkRelations: Record<PrismicPageType, PrismicPageLinkResolver> = {
  layout: 'home',
  home: 'home',
  pages: doc => ({
    to: 'pages',
    params: { uid: doc.uid },
  }),
  contact: 'contact',
};

function prismicLinkResolverToCustomLinkProps(
  resolver: PrismicPageLinkResolver,
  document: Omit<FilledLinkToDocumentField, 'url'>,
): CustomLinkProps {
  if (typeof resolver === 'string') {
    return { to: resolver };
  } else if (typeof resolver === 'function') {
    return prismicLinkResolverToCustomLinkProps(resolver(document), document);
  } else if (isExternalUrlResolver(resolver)) {
    return { href: resolver.url };
  } else {
    return resolver;
  }
}

export function getCustomLinkPropsFromPrismicDocument(
  document: Omit<FilledLinkToDocumentField, 'url'>,
): Omit<CustomLinkProps, 'ref' | 'as'> {
  const type = document.type as PrismicPageType;

  return pipe(
    O.fromNullable(linkRelations[type]),
    O.fold<PrismicPageLinkResolver, CustomLinkProps>(
      () => ({ to: 'home' }),
      resolver => prismicLinkResolverToCustomLinkProps(resolver, document),
    ),
  );
}

export const defaultLinkResolver: prismicH.LinkResolverFunction = doc => {
  const linkProps = getCustomLinkPropsFromPrismicDocument(doc);

  if (isRequiredCustomLinkProps(linkProps)) {
    return getLinkFromLinkProps(linkProps);
  } else {
    return linkProps.href ?? '/';
  }
};

export const defaultLinkResolverWithLocale: prismicH.LinkResolverFunction = doc => {
  const uri = defaultLinkResolver(doc);

  return `/${prismicLocaleToLocale(doc.lang)}${uri}`;
};
