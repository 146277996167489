import React, { FC, ReactNode } from 'react';
import { RouteKey } from '../../../routes';
import { RequiredCustomLinkProps } from '@shared/components/link/CustomLink';

import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { getPublicRuntimeConfigValue } from '@shared/utils/config';
import Head from 'next/head';
import { renderNullable } from '@shared/utils/render';
import { getLinkFromLinkProps } from '@shared/utils/routes';
import { buildImgixSrcAndSrcSets } from '@shared/utils/images';
import { TITLE_SUFFIX } from '@core/constants';

function getMetaLinkFromLinkProps(
  type: 'canonical' | 'prev' | 'next' | 'og:url',
  params?: RouteKey | RequiredCustomLinkProps,
): ReactNode {
  return pipe(
    O.Do,
    O.apS('params', O.fromNullable(params)),
    O.apS('host', getPublicRuntimeConfigValue('HOST')),
    O.map(({ params, host }) => {
      const link = getLinkFromLinkProps(typeof params === 'string' ? { to: params } : params);

      return `${host}${link}`;
    }),
    O.map(link =>
      type === 'og:url' ? (
        <meta key="og:url" property="og:url" content={link} />
      ) : (
        <link key={type} rel={type} href={link} />
      ),
    ),
    O.toNullable,
  );
}

export interface SeoProps {
  title?: string | null;
  description?: string | null;
  noIndex?: boolean;
  canonical?: RouteKey | RequiredCustomLinkProps;
  prev?: RouteKey | RequiredCustomLinkProps;
  next?: RouteKey | RequiredCustomLinkProps;
  image?: string | null;
}

const Seo: FC<SeoProps> = ({ title, description, noIndex: reveiveNoIndex = true, canonical, prev, next, image }) => {
  const isProd = pipe(
    getPublicRuntimeConfigValue('ENVIRONMENT'),
    O.exists(environment => environment === 'production'),
  );

  const noIndex = reveiveNoIndex || !isProd;

  return (
    <Head>
      {pipe(
        O.fromNullable(title),
        O.map(t => `${t} - ${TITLE_SUFFIX}`),
        O.map(t => (
          <>
            <title key="title">{t}</title>
            <meta key="og:title" property="og:title" content={t} />
          </>
        )),
        O.toNullable,
      )}

      {renderNullable(description, d => (
        <>
          <meta key="description" name="description" content={d} />
          <meta key="og:description" property="og:description" content={d} />
        </>
      ))}

      {noIndex && <meta key="robots" name="robots" content="noindex" />}
      <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=0" />

      {getMetaLinkFromLinkProps('canonical', canonical)}
      {getMetaLinkFromLinkProps('prev', prev)}
      {getMetaLinkFromLinkProps('next', next)}

      <meta key="og:type" property="og:type" content="website" />

      {getMetaLinkFromLinkProps('og:url', canonical)}

      {renderNullable(image, image => (
        <meta
          key="og:image"
          property="og:image"
          content={buildImgixSrcAndSrcSets(image, { w: 1200, height: 630, fit: 'crop', fm: 'jpg' }, false)[0]}
        />
      ))}
    </Head>
  );
};

export default Seo;
