import queryString, { ParsedQuery, StringifiableRecord } from 'query-string';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as String from 'fp-ts/string';
import * as EI from 'fp-ts/Either';
import * as TE from 'fp-ts/TaskEither';
import { camelToSnake } from '@shared/utils/string';
import { ParsedUrlQuery } from 'querystring';
import { HttpError, HttpResult, HttpTask } from '@core/http';

export function queriesToSnakeCase(queries: StringifiableRecord): StringifiableRecord {
  return pipe(
    queries,
    R.reduceWithIndex(String.Ord)({} as StringifiableRecord, (key, acc, value) => ({
      ...acc,
      [camelToSnake(key)]: value,
    })),
  );
}

export function stringifyQueries(queries: StringifiableRecord): string {
  return queryString.stringify(queriesToSnakeCase(queries), { skipEmptyString: true, skipNull: true });
}

export function getStringQuery<T extends string = string>(
  query: ParsedQuery | ParsedUrlQuery,
  key: string,
): O.Option<T> {
  return pipe(
    O.fromNullable(query[key] as T | T[] | null),
    O.chain(value => (Array.isArray(value) ? A.head(value) : O.some(value))),
  );
}

export function queryToHttpResult<T>(value: O.Option<T>): HttpResult<T> {
  return pipe(
    value,
    EI.fromOption(() => HttpError.notFound),
  );
}

export function queryToHttpTask<T>(value: O.Option<T>): HttpTask<T> {
  return TE.fromEither(queryToHttpResult(value));
}
