import {
  AccountParams,
  Authenticate,
  AuthenticateParams,
  EmailParams,
  ForgotPasswordParams,
  Profile,
  ResetPasswordDetail,
  ResetPasswordParams,
  SignupParams,
} from '@modules/auth/model';
import { HttpTask } from '@core/http';
import httpService from '@core/http/service';
import { pipe } from 'fp-ts/function';
import { NextPageContext } from 'next';
import * as TE from 'fp-ts/TaskEither';
import { Language } from '@shared/modules/translation/model';

export function authenticate(params: AuthenticateParams): HttpTask<Profile> {
  return pipe(
    httpService.post('/authenticate', params),
    TE.chain(() => getProfile()),
  );
}

export function authenticateLimacapt(params: Authenticate.LimacaptLoginIn, ctx: NextPageContext): HttpTask {
  return pipe(
    httpService.post('/authenticate/limacapt', params, ctx, {}, true),
    TE.chainFirstIOK(({ headers }) => () => {
      const setCookie = headers['set-cookie'];
      if (setCookie) ctx.res?.setHeader('set-cookie', setCookie);
    }),
  );
}

export function logout(): HttpTask {
  return httpService.delete('/authenticate');
}

export function getProfile(ctx?: NextPageContext): HttpTask<Profile> {
  return httpService.get('/profile', ctx);
}

export function updateProfile(params: AccountParams, ctx?: NextPageContext): HttpTask<Profile> {
  return httpService.post('/profile', params, ctx);
}

export function updateProfileLanguage(language: Language): HttpTask {
  return httpService.post(`/profile/language`, { language });
}

export function updateEmail(params: EmailParams): HttpTask<Profile> {
  return httpService.post('/profile/email', params);
}

export function deleteProfile(): HttpTask {
  return httpService.delete('/profile');
}

export function signUp(params: SignupParams): HttpTask {
  return httpService.post('/sign-up', params);
}

export function forgotPassword(params: ForgotPasswordParams): HttpTask {
  return httpService.post('/password-reset/forgot', params);
}

export function getResetPasswordDetail(token: string, ctx: NextPageContext): HttpTask<ResetPasswordDetail> {
  return httpService.get(`/password-reset/${token}`, ctx);
}

export function resetPassword(token: string, params: ResetPasswordParams): HttpTask {
  return httpService.post(`/password-reset/${token}`, params);
}

export function getActivationDetail(token: string, ctx: NextPageContext): HttpTask<ResetPasswordDetail> {
  return httpService.get(`/activation/${token}`, ctx);
}

export function activateAccount(token: string, params: ResetPasswordParams): HttpTask<Profile> {
  return pipe(
    httpService.post(`/activation/${token}`, params),
    TE.chain(() => getProfile()),
  );
}
