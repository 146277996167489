import Router from 'next/router';

import routes, { Route, RouteKey, RouteParams, RouteQueries } from '../../routes';
import { stringifyUrl } from 'query-string';
import { queriesToSnakeCase } from '@shared/utils/queries';
import { compile } from 'path-to-regexp';
import { RequiredCustomLinkProps } from '@shared/components/link/CustomLink';

export function getPage(page: string, params: RouteParams = {}, queries: RouteQueries = {}): string {
  return stringifyUrl(
    { url: page, query: queriesToSnakeCase({ ...params, ...queries }) },
    { skipEmptyString: true, skipNull: true },
  );
}

export function getLink(path: string, params: RouteParams = {}, queries: RouteQueries = {}, hash?: string): string {
  const pathWithParams = compile(`/:prefix?${path}`)(params);

  return stringifyUrl(
    { url: pathWithParams, query: queriesToSnakeCase(queries), fragmentIdentifier: hash },
    { skipEmptyString: true, skipNull: true },
  );
}

export function getLinkFromLinkProps({ to, params, queries, hash }: RequiredCustomLinkProps): string {
  return getLink(routes[to].path, params, queries, hash);
}

export type RouteOptions = { shallow?: boolean; resetScroll?: boolean; scroll?: boolean };

export function navigateToRoute(
  route: Route,
  params: RouteQueries = {},
  queries: RouteQueries = {},
  options?: RouteOptions,
  hash?: string,
): Promise<boolean> {
  return Router.push(getPage(route.page, params, queries), getLink(route.path, params, queries, hash), options).then(
    res => {
      if (!options?.resetScroll) {
        window.scrollTo(0, 0);
      }

      return res;
    },
  );
}

export function navigateTo(
  key: RouteKey,
  params: RouteParams = {},
  queries: RouteQueries = {},
  options?: RouteOptions,
  hash?: string,
): Promise<boolean> {
  return navigateToRoute(routes[key], params, queries, options, hash);
}

export function replaceRouteBy(
  key: RouteKey,
  params: RouteParams = {},
  queries: RouteQueries = {},
  options: RouteOptions = {},
  hash?: string,
): Promise<boolean> {
  const route = routes[key];

  return Router.replace(getPage(route.page, params, queries), getLink(route.path, params, queries, hash), options).then(
    res => {
      if (options.resetScroll !== false) {
        window.scrollTo(0, 0);
      }

      return res;
    },
  );
}
