import styled from 'styled-components';

import searchIconWhite from '@assets/icons/search-white.svg';

export const SearchInputContainer = styled.form``;

export const SearchInput = styled.input`
  padding: 0 50px 0 20px;
  height: 60px;
  width: 100%;
  border-radius: 6px;
  border: none;
  font: inherit;
  background: url(${searchIconWhite.src}) no-repeat right 15px center rgba(255, 255, 255, 0.34);
  outline: none;
  color: #fff;
  box-sizing: border-box;

  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
`;
