import axios from 'axios';
import { stringifyQueries } from '@shared/utils/queries';
import { identity, pipe } from 'fp-ts/function';
import { isServer } from '@shared/utils/next';
import * as B from 'fp-ts/boolean';
import * as O from 'fp-ts/Option';
import * as EI from 'fp-ts/Either';
import { getServerRuntimeConfigValue } from '@shared/utils/config';

function getBaseURL(): string | undefined {
  return pipe(
    isServer(),
    B.fold(
      () => O.some('/front'),
      () =>
        pipe(
          getServerRuntimeConfigValue('BACKEND_URL'),
          O.chain(url =>
            pipe(
              EI.tryCatch(() => new URL('/front', url).toString(), identity),
              O.fromEither,
            ),
          ),
        ),
    ),
    O.toUndefined,
  );
}

const axiosInstance = axios.create({
  paramsSerializer: stringifyQueries,
  withCredentials: true,
  baseURL: getBaseURL(),
});

export default axiosInstance;
