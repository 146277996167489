import React, { FC, useEffect, useRef, useState } from 'react';
import * as Styled from './Nav.styles';
import * as O from 'fp-ts/Option';
import { LayoutPrismicContentLinks } from '@layout/model';
import { GroupField } from '@prismicio/types';
import { getLinkPropsFromPrismicLink } from '@core/prismic';
import Router, { useRouter } from 'next/router';
import frIcon from '@assets/logos/fr.png';
import enIcon from '@assets/logos/en.png';
import { useTranslations } from '@shared/modules/translation/hooks';
import { switchLocale } from '@shared/modules/translation/utils';
import { Locale } from '@shared/modules/translation/model';
import { Profile } from '@modules/auth/model';
import { renderOptional } from '@shared/utils/render';
import { RouteKey } from '../../../routes';
import { navigateTo } from '@shared/utils/routes';
import { useAuthContext } from '@modules/auth/context';
import NavSearch from '@layout/header/nav/search/NavSearch';

interface NavProps {
  links: GroupField<LayoutPrismicContentLinks>;
  show: boolean;
  profile: O.Option<Profile>;
  transparent: boolean;
}

const Nav: FC<NavProps> = ({ links, show, profile, transparent }) => {
  const { logout } = useAuthContext();

  const languageMenuRef = useRef<HTMLDivElement | null>(null);
  const profileMenuRef = useRef<HTMLDivElement | null>(null);

  const [languageOpen, setLanguageOpen] = useState<boolean>(false);
  const [profileOpen, setProfileOpen] = useState<boolean>(false);

  const router = useRouter();
  const translations = useTranslations();

  const handleOpenProfile = () => setProfileOpen(old => !old);

  const handleChangeRoute = (route: RouteKey) => () => {
    setProfileOpen(false);
    navigateTo(route);
  };

  const handleLogout = () => {
    setProfileOpen(false);
    logout();
  };

  const handleOpenLanguage = () => setLanguageOpen(old => !old);

  const handleChangeLanguage = (locale: Locale) => () => {
    switchLocale(locale);
    setLanguageOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (languageMenuRef.current && e.target instanceof Element && !languageMenuRef.current.contains(e.target)) {
        setLanguageOpen(false);
      }

      if (profileMenuRef.current && e.target instanceof Element && !profileMenuRef.current.contains(e.target)) {
        setProfileOpen(false);
      }
    };

    const closeAllMenu = () => {
      setLanguageOpen(false);
      setProfileOpen(false);
    };

    Router.events.on('routeChangeStart', closeAllMenu);
    document.addEventListener('mousedown', handleClickOutside, { passive: true });

    return () => {
      Router.events.off('routeChangeStart', closeAllMenu);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Styled.NavContainer show={show}>
      <Styled.NavLinks>
        <Styled.NavMenu>Menu</Styled.NavMenu>
        {links.map((link, index) => (
          <Styled.NavLink
            title={link.label ?? ''}
            {...getLinkPropsFromPrismicLink(link.link)}
            key={index}
            $transparent={transparent}>
            {link.label}
          </Styled.NavLink>
        ))}
      </Styled.NavLinks>

      <Styled.NavRight>
        <Styled.NavProfileContainer>
          {renderOptional(
            profile,
            profile => (
              <Styled.NavProfile onClick={handleOpenProfile}>
                <p>
                  {profile.firstName?.charAt(0)}
                  {profile.lastName?.charAt(0)}
                </p>
              </Styled.NavProfile>
            ),
            () => (
              <Styled.NavLogin to="sign-in" $transparent={transparent} queries={{ referrer: router.asPath }}>
                {translations.header.login}
              </Styled.NavLogin>
            ),
          )}

          {profileOpen ? (
            <Styled.NavProfileChoice ref={profileMenuRef}>
              <Styled.NavProfileChoiceLink onClick={handleChangeRoute('account')}>
                {translations.user.menu.profile}
              </Styled.NavProfileChoiceLink>

              <Styled.NavProfileChoiceDivider />

              <Styled.NavProfileChoiceDisconnect onClick={handleLogout}>
                {translations.user.menu.logout}
              </Styled.NavProfileChoiceDisconnect>
            </Styled.NavProfileChoice>
          ) : null}
        </Styled.NavProfileContainer>

        <Styled.NavRightSeparator $transparent={transparent} />

        <NavSearch transparent={transparent} />

        <Styled.NavRightLanguage>
          <Styled.NavRightLanguageLabel onClick={handleOpenLanguage} $transparent={transparent}>
            {router.locale === 'fr' ? 'FR' : 'EN'}
          </Styled.NavRightLanguageLabel>

          {languageOpen ? (
            <Styled.NavRightLanguageChoice ref={languageMenuRef}>
              <Styled.NavRightLanguageContent>
                <Styled.NavRightLanguageItem onClick={handleChangeLanguage('fr')}>
                  <Styled.NavRightLanguageItemImage src={frIcon} alt="FR" />
                  <Styled.NavRightLanguageItemLabel active={router.locale === 'fr'}>
                    {translations.language.french}
                  </Styled.NavRightLanguageItemLabel>
                </Styled.NavRightLanguageItem>

                <Styled.NavRightLanguageItem onClick={handleChangeLanguage('en')}>
                  <Styled.NavRightLanguageItemImage src={enIcon} alt="EN" />
                  <Styled.NavRightLanguageItemLabel active={router.locale === 'en'}>
                    {translations.language.english}
                  </Styled.NavRightLanguageItemLabel>
                </Styled.NavRightLanguageItem>
              </Styled.NavRightLanguageContent>
            </Styled.NavRightLanguageChoice>
          ) : null}
        </Styled.NavRightLanguage>
      </Styled.NavRight>

      <Styled.NavMobileSearchInput />
    </Styled.NavContainer>
  );
};

export default Nav;
