import styled from 'styled-components';

import { mediaMax } from '@styles/utils';

import searchIcon from '@assets/icons/search.svg';
import searchIconWhite from '@assets/icons/search-white.svg';

import closeIcon from '@assets/icons/dark-close.svg';
import closeIconWhite from '@assets/icons/white-close.svg';

export const NavSearchContainer = styled.form`
  position: relative;

  @media screen and (${mediaMax.tablet}) {
    display: none;
  }
`;

export const NavSearchInput = styled.input<{ $open: boolean; $transparent: boolean }>`
  width: ${props => (props.$open ? '250px' : '20px')};
  height: 45px;
  padding: ${props => (props.$open ? '0 35px' : '0')};
  background: url(${props => (props.$transparent ? searchIconWhite.src : searchIcon.src)}) no-repeat center left;
  border: none;
  border-bottom: 1px solid ${props => (props.$open ? (props.$transparent ? '#fff' : '#707070') : 'transparent')};
  font: inherit;
  transition: all 0.15s linear;
  color: ${props => (props.$transparent ? '#fff' : 'inherit')};
  cursor: ${props => (props.$open ? 'text' : 'pointer')};
`;

export const NavSearchClearButton = styled.button<{ $open: boolean; $transparent: boolean }>`
  display: ${props => (props.$open ? 'block' : 'none')};
  position: absolute;
  right: 0;
  top: 50%;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  background: url(${props => (props.$transparent ? closeIconWhite.src : closeIcon.src)}) no-repeat center center;
  background-size: 100%;
  border: none;
  outline: none;
  cursor: pointer;
`;
