import * as Sentry from '@sentry/nextjs';

import * as O from 'fp-ts/Option';
import * as IO from 'fp-ts/IO';

import { constVoid, pipe } from 'fp-ts/function';
import { sequenceS } from 'fp-ts/Apply';
import { getPublicRuntimeConfigValue } from '@shared/utils/config';

function getSentryConfig(): O.Option<Parameters<typeof Sentry.init>[0]> {
  return pipe(
    sequenceS(O.Apply)({
      dsn: getPublicRuntimeConfigValue('SENTRY_DSN'),
      environment: getPublicRuntimeConfigValue('ENVIRONMENT'),
      release: getPublicRuntimeConfigValue('RELEASE'),
    }),
    O.map(options => ({
      ...options,
      tracesSampleRate: 1.0,
    })),
  );
}

const initSentry: IO.IO<void> = pipe(
  getSentryConfig(),
  O.fold(
    () => constVoid,
    config => () => {
      Sentry.init(config);
    },
  ),
);

initSentry();
