import styled from 'styled-components';
import { fontSize, fontWeight, mediaMax, tertiaryColor } from '@styles/utils';
import CustomLink from '@shared/components/link/CustomLink';

export const FooterWrapper = styled.div`
  margin-top: 64px;
  border-top: solid 1px rgba(112, 112, 112, 0.2);
`;

export const FooterContainer = styled.div`
  margin: 0 auto;
  padding: 64px 16px 40px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  background-color: ${tertiaryColor(100)};
  width: 100%;
  max-width: 1090px;
`;

export const FooterTop = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (${mediaMax.tablet}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 40px;
  }
`;

export const FooterTopItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  width: 250px;

  @media screen and (${mediaMax.tablet}) {
    width: max-content;
  }
`;

export const FooterTopItemLogo = styled.div`
  width: 100px;
`;

export const FooterTopItemTitle = styled.p`
  padding-bottom: 8px;
  ${fontSize.standard};
  font-weight: ${fontWeight.semiBold};
  color: ${tertiaryColor(700)};
`;

export const FooterTopItemLink = styled(CustomLink)`
  text-decoration: none;
  ${fontSize.small};
  font-weight: ${fontWeight.normal};
  color: ${tertiaryColor(400)};
`;

export const FooterBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px rgba(112, 112, 112, 0.2);
  padding-top: 28px;

  @media screen and (${mediaMax.tablet}) {
    padding-top: 25px;
    flex-direction: column;
    row-gap: 26px;
  }
`;

export const FooterBottomLeft = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FooterBottomLeftLabel = styled.p`
  ${fontSize.extraSmall};
  font-weight: ${fontWeight.normal};
  color: ${tertiaryColor(400)};
`;

export const FooterBottomLeftLink = styled(CustomLink)`
  margin-left: 40px;
  text-decoration: none;
  ${fontSize.extraSmall};
  font-weight: ${fontWeight.normal};
  color: ${tertiaryColor(400)};

  @media screen and (${mediaMax.tablet}) {
    margin-left: 30px;
  }
`;

export const FooterBottomRight = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 26px;
`;
