import React, { FC } from 'react';
import * as Styled from './FullError.styles';
import { HttpError, HttpStatusCode } from '@core/http';
import CustomLink from '@shared/components/link/CustomLink';
import Image from 'next/image';
import errorIcon from '@assets/icons/error/error.svg';
import Seo from '@shared/components/seo/Seo';
import { useTranslations } from '@shared/modules/translation/hooks';
import { Translations } from '@shared/modules/translation/locales';

function getTitle(error: HttpError, translations: Translations): string {
  switch (error.status) {
    case HttpStatusCode.NOT_FOUND:
      return translations.error.page.title;
    default:
      return `${translations.error.page.seoTitle} ${error.status}`;
  }
}

function getDescription(error: HttpError, translations: Translations): string {
  switch (error.status) {
    case HttpStatusCode.NOT_FOUND:
      return translations.error.page.description;
    default:
      return translations.error.message.default;
  }
}

interface FullErrorProps {
  error: HttpError;
}

const FullError: FC<FullErrorProps> = ({ error }) => {
  const translations = useTranslations();

  const is404 = HttpStatusCode.NOT_FOUND === error.status;

  return (
    <Styled.FullErrorContainer $is404={is404}>
      <Seo title={translations.error.page.seoTitle} />

      {is404 ? <Image src={errorIcon} /> : null}
      <Styled.FullErrorTitle>{getTitle(error, translations)}</Styled.FullErrorTitle>

      <Styled.FullErrorDescription>{getDescription(error, translations)}</Styled.FullErrorDescription>

      <Styled.FullErrorButtonContainer>
        <Styled.FullErrorButton as={CustomLink} to="home">
          {translations.error.page.home}
        </Styled.FullErrorButton>
      </Styled.FullErrorButtonContainer>
    </Styled.FullErrorContainer>
  );
};

export default FullError;
