import {
  BackgroundColor,
  Colors,
  ComplementaryColors,
  DefaultTheme,
  Font,
  PrimaryColor,
  SecondaryColor,
  TertiaryColor,
  TextColors,
} from 'styled-components';

const primaryColor: PrimaryColor = {
  900: '#1f3a2f',
  800: '#284f3f',
  700: '#386d58',
  600: '#09553f',
  500: '#00664b',
  400: '#46886f',
  300: '#5bb08e',
  200: '#71c9a8',
  100: '#e8f5ee',
};

const secondaryColor: SecondaryColor = {
  900: '#a36e1b',
  800: '#cc8a21',
  700: '#e0931b',
  600: '#f4990c',
  500: '#f5a528',
  400: '#f7b44c',
  300: '#fad294',
  200: '#fce1b7',
  100: '#fdf0db',
};

const tertiaryColor: TertiaryColor = {
  900: '#000000',
  800: '#222222',
  700: '#2e3234',
  600: '#43454e',
  500: '#5b5a5a',
  400: '#777774',
  300: '#dddddd',
  200: '#efefef',
  100: '#ffffff',
};

const backgroundColor: BackgroundColor = {
  400: '#c2b3af',
  300: '#cec7c4',
  200: '#d7d4d3',
  100: '#ebe4e1',
};

const textColors: TextColors = {
  default: tertiaryColor[700],
  highlight: '#ffffff',
  grey: tertiaryColor[400],
  link: primaryColor[400],
};

const complementaryColors: ComplementaryColors = {
  white: '#f7f7f7',
  paleGrey: '#fdf9f4',
  lightPink: '#faf8f8',
  veryLightPink: '#f2f3f4',
};

const colors: Colors = {
  primary: primaryColor,
  secondary: secondaryColor,
  tertiary: tertiaryColor,
  background: backgroundColor,
  text: textColors,
  complementary: complementaryColors,
  warning: '#f87979',
};

const font: Font = {
  family: "font-family: 'Jost', sans-serif",
  weights: {
    normal: '400',
    standard: '500',
    semiBold: '600',
    bold: '700',
  },
  sizes: {
    h1: {
      desktop: '34px',
      mobile: '27px',
    },
    h2: {
      desktop: '29px',
      mobile: '23px',
    },
    h3: {
      desktop: '24px',
      mobile: '19px',
    },
    h4: {
      desktop: '16px',
      mobile: '13px',
    },
    large: {
      desktop: '16px',
      mobile: '13px',
    },
    standard: {
      desktop: '14px',
      mobile: '11px',
    },
    label: {
      desktop: '13px',
      mobile: '10px',
    },
    small: {
      desktop: '12px',
      mobile: '10px',
    },
    extraSmall: {
      desktop: '11px',
      mobile: '9px',
    },
  },
};

const theme: DefaultTheme = {
  colors,
  font,
  breakpoints: {
    extraLarge: 1440,
    large: 1280,
    desktop: 1080,
    tablet: 960,
    mobile: 600,
  },
  sizes: {
    content: 1380,
  },
};

export default theme;
