import React, { FC } from 'react';
import * as Styled from './SocialNetworks.styles';
import * as prismicT from '@prismicio/types';
import Image from 'next/image';
import CustomLink, { CustomLinkProps } from '@shared/components/link/CustomLink';
import { getLinkPropsFromPrismicLink } from '@core/prismic';
import youtubeIcon from '@assets/icons/social-networks/youtube.svg';
import instagramIcon from '@assets/icons/social-networks/instagram.svg';
import twitterIcon from '@assets/icons/social-networks/twitter.svg';
import linkedinIcon from '@assets/icons/social-networks/linkedin.svg';

type SocialNetworksType = 'youtube' | 'instagram' | 'twitter' | 'linkedin';

const socialNetworksIcon: Record<SocialNetworksType, string> = {
  youtube: youtubeIcon,
  instagram: instagramIcon,
  twitter: twitterIcon,
  linkedin: linkedinIcon,
};

interface SocialNetworksItemProps {
  link: Omit<CustomLinkProps, 'ref' | 'as'>;
  type: SocialNetworksType;
}

const SocialNetworksItem: FC<SocialNetworksItemProps> = ({ link, type }) => {
  return link.href != null ? (
    <CustomLink {...link}>
      <Image src={socialNetworksIcon[type]} alt={type} />
    </CustomLink>
  ) : null;
};

interface SocialNetworksProps {
  youtube: prismicT.LinkField;
  instagram: prismicT.LinkField;
  twitter: prismicT.LinkField;
  linkedin: prismicT.LinkField;
}

const SocialNetworks: FC<SocialNetworksProps> = ({ youtube, instagram, twitter, linkedin }) => (
  <Styled.SocialNetworksContainer>
    <SocialNetworksItem link={getLinkPropsFromPrismicLink(youtube)} type="youtube" />

    <SocialNetworksItem link={getLinkPropsFromPrismicLink(instagram)} type="instagram" />

    <SocialNetworksItem link={getLinkPropsFromPrismicLink(twitter)} type="twitter" />

    <SocialNetworksItem link={getLinkPropsFromPrismicLink(linkedin)} type="linkedin" />
  </Styled.SocialNetworksContainer>
);

export default SocialNetworks;
