import { css, DefaultTheme, FlattenInterpolation, FontSizes, FontWeights, ThemeProps } from 'styled-components';

import theme from '@styles/theme';

import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as String from 'fp-ts/string';
import { mediaMax } from '@styles/utils/responsive';

export const fontFamily = (props: ThemeProps<DefaultTheme>) => props.theme.font.family;

export const fontWeight = pipe(
  theme.font.weights,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<Record<keyof FontWeights, (props: ThemeProps<DefaultTheme>) => number>>,
    (key, acc) => ({
      ...acc,
      [key]: (props: ThemeProps<DefaultTheme>) => props.theme.font.weights[key],
    }),
  ),
);

export const fontSize = pipe(
  theme.font.sizes,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<Record<FontSizes, FlattenInterpolation<ThemeProps<DefaultTheme>>>>,
    (key, acc) => ({
      ...acc,
      [key]: css`
        ${props => {
          const size = props.theme.font.sizes[key];

          return css`
            font-size: ${size.desktop};

            ${size.smallDesktop != null &&
            size.desktop !== size.smallDesktop &&
            css`
              @media screen and (${mediaMax.extraLarge}) {
                font-size: ${size.smallDesktop};
              }
            `}

            ${size.mobile != null &&
            size.desktop !== size.mobile &&
            css`
              @media screen and (${mediaMax.tablet}) {
                font-size: ${size.mobile};
              }
            `}
          `;
        }};
      `,
    }),
  ),
);
