import * as Prismic from '@prismicio/client';

import { Lazy, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { NextPageContext } from 'next';
import { HttpError, HttpTask } from '@core/http';
import { PrismicContent, PrismicDocument, PrismicPageType } from '@core/prismic/model';
import { defaultLinkResolverWithLocale } from '@core/prismic/resolver';
import { PRISMIC_REPOSITORY } from '@core/constants';
import { getCurrentLocale } from '@shared/modules/translation/utils';

const ENDPOINT = Prismic.getRepositoryEndpoint(PRISMIC_REPOSITORY);

export function getPrismicClient({ req }: NextPageContext, client?: Prismic.Client): T.Task<Prismic.Client> {
  return pipe(
    O.fromNullable(client),
    O.fold(
      () => T.of(Prismic.createClient(ENDPOINT)),
      client => T.of(client),
    ),
    T.map(client => {
      if (req) {
        client.enableAutoPreviewsFromReq(req);
      }

      return client;
    }),
  );
}

function getPrismicLocale(ctx: NextPageContext): string {
  return getCurrentLocale(ctx) === 'en' ? 'en-gb' : 'fr-fr';
}

function transformPrismicRequest<T>(request: Lazy<Promise<T>>): HttpTask<T> {
  return TE.tryCatch(request, err => {
    console.error(err);

    if (err instanceof Prismic.PrismicError && !err.response) {
      return HttpError.notFound;
    }

    return HttpError.default;
  });
}

export function getPrismicDocumentsById<R extends PrismicContent>(
  id: string,
  client?: Prismic.Client,
): (ctx: NextPageContext) => HttpTask<PrismicDocument<R>> {
  return ctx =>
    pipe(
      getPrismicClient(ctx, client),
      T.chain(client =>
        transformPrismicRequest(() => client.getByID<PrismicDocument<R>>(id, { lang: getPrismicLocale(ctx) })),
      ),
    );
}

export function getPrismicDocumentsByIds<R extends PrismicContent>(
  ids?: Array<string>,
  client?: Prismic.Client,
): (ctx: NextPageContext) => HttpTask<Array<PrismicDocument<R>>> {
  return ctx =>
    pipe(
      getPrismicClient(ctx, client),
      T.chain(client =>
        transformPrismicRequest(() =>
          client.getByIDs<PrismicDocument<R>>(ids?.filter(id => !!id) ?? [], { lang: getPrismicLocale(ctx) }),
        ),
      ),
      TE.map(({ results }) => results),
    );
}

export function getPrismicDocumentByType<R extends PrismicContent>(
  type: PrismicPageType,
  client?: Prismic.Client,
): (ctx: NextPageContext) => HttpTask<PrismicDocument<R>> {
  return ctx =>
    pipe(
      getPrismicClient(ctx, client),
      T.chain(client =>
        transformPrismicRequest(() => client.getSingle<PrismicDocument<R>>(type, { lang: getPrismicLocale(ctx) })),
      ),
    );
}

export function getPrismicDocumentByUid<R extends PrismicContent>(
  type: PrismicPageType,
  uid: string,
  client?: Prismic.Client,
): (ctx: NextPageContext) => HttpTask<PrismicDocument<R>> {
  return ctx =>
    pipe(
      getPrismicClient(ctx, client),
      T.chain(client =>
        transformPrismicRequest(() => client.getByUID<PrismicDocument<R>>(type, uid, { lang: getPrismicLocale(ctx) })),
      ),
    );
}

export function getPrismicPreview(
  previewToken: string,
  documentID: string,
): (ctx: NextPageContext) => HttpTask<string> {
  return () =>
    pipe(
      T.of(Prismic.createClient(ENDPOINT)),
      T.chain(client =>
        transformPrismicRequest(() =>
          client.resolvePreviewURL({
            previewToken,
            documentID,
            defaultURL: '/',
            linkResolver: defaultLinkResolverWithLocale,
          }),
        ),
      ),
    );
}
