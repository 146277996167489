import styled from 'styled-components';

export const ImageContainer = styled.div`
  img,
  picture,
  source {
    width: 100%;
    height: auto;

    &.lazyload:not([src]) {
      visibility: hidden;
    }
  }
`;
