import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import theme from '@styles/theme';
import { GlobalStyle } from '@styles/global';
import Script from 'next/script';
import Layout from '@layout/Layout';
import { PRISMIC_REPOSITORY } from '@core/constants';
import { getCurrentLocale } from '@shared/modules/translation/utils';
import { defaultLinkResolver, getPrismicDocumentByType, PrismicDocument } from '@core/prismic';
import { LayoutPrismicContent } from '@layout/model';
import { handleInitialPropsHttpError, HttpResult, HttpTask } from '@core/http';
import { Locale } from '@shared/modules/translation/model';
import { pipe } from 'fp-ts/function';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as O from 'fp-ts/Option';
import * as TO from 'fp-ts/TaskOption';
import * as EI from 'fp-ts/Either';
import { renderHttpResult } from '@shared/utils/render';
import '@glidejs/glide/dist/css/glide.core.min.css';
import { Profile } from '@modules/auth/model';
import { getProfile } from '@modules/auth/service';
import AuthContextProvider from '@modules/auth/context';
import { useEffect } from 'react';
import { useCurrentLocale } from '@shared/modules/translation/hooks';
import { PrismicProvider } from '@prismicio/react';
import CustomLink from '@shared/components/link/CustomLink';

export interface DeSangosseAppData {
  layout: PrismicDocument<LayoutPrismicContent>;
}

export interface DeSangosseAppInitialProps {
  data: HttpResult<DeSangosseAppData>;
  profile: O.Option<Profile>;
}

let caches: Record<Locale, Partial<DeSangosseAppData>> = {
  fr: {},
  en: {},
};

const DeSangosseApp = ({ Component, pageProps, data, profile }: AppProps & DeSangosseAppInitialProps) => {
  const locale = useCurrentLocale();

  const layoutProps = Component?.getLayoutProps !== undefined ? Component.getLayoutProps() : {};

  useEffect(() => {
    if (EI.isRight(data)) {
      caches[locale] = data.right;
    }
  }, [locale, data]);

  return (
    <PrismicProvider linkResolver={defaultLinkResolver} internalLinkComponent={CustomLink}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />

        <AuthContextProvider profile={profile}>
          {renderHttpResult(data, ({ layout }) => (
            <Layout {...layoutProps} layout={layout.data}>
              <Component {...pageProps} />
            </Layout>
          ))}
        </AuthContextProvider>

        <Script
          strategy="lazyOnload"
          async
          defer
          src={`https://static.cdn.prismic.io/prismic.js?new=true&repo=${PRISMIC_REPOSITORY}`}
        />

        <Script strategy="afterInteractive" src="https://unpkg.com/lazysizes@5.3.2/lazysizes.min.js" />
        <Script
          strategy="afterInteractive"
          src="https://unpkg.com/lazysizes@5.3.2/plugins/attrchange/ls.attrchange.min.js"
        />
      </ThemeProvider>
    </PrismicProvider>
  );
};

function cacheOrApi<A>(task: HttpTask<A>, cacheData?: A): HttpTask<A> {
  return pipe(
    O.fromNullable(cacheData),
    O.fold(() => task, TE.right),
  );
}

DeSangosseApp.getInitialProps = (appContext: AppContext): Promise<AppInitialProps & DeSangosseAppInitialProps> => {
  const { ctx } = appContext;

  const locale = getCurrentLocale(ctx);

  const data = pipe(
    sequenceS(TE.ApplyPar)({
      layout: cacheOrApi(getPrismicDocumentByType<LayoutPrismicContent>('layout')(ctx), caches[locale].layout),
    }),
    TE.orElseFirstTaskK(err => handleInitialPropsHttpError(ctx, err)),
  );

  return pipe(
    sequenceS(T.ApplyPar)({
      data,
      profile: TO.fromTaskEither(getProfile(ctx)),
      appProps: () => App.getInitialProps(appContext),
    }),
    T.map(({ data, profile, appProps }) => ({ ...appProps, profile, data })),
  )();
};

export default DeSangosseApp;
