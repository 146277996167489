import { ReactNode } from 'react';
import { constNull, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as B from 'fp-ts/boolean';
import * as EI from 'fp-ts/Either';
import { HttpError, HttpResult } from '@core/http';
import FullError from '@modules/error/components/FullError';

export function renderOptional<T, R extends ReactNode>(
  opt: O.Option<T>,
  onSome: (data: T) => R,
  onNone: () => R | null = constNull,
): R | null {
  return pipe(opt, O.fold(onNone, onSome));
}

export function renderNullable<T, R extends ReactNode>(
  value: T | null | undefined,
  onSome: (data: T) => R,
  onNone: () => R | null = constNull,
): R | null {
  return renderOptional(O.fromNullable(value), onSome, onNone);
}

export function renderConditional<R extends ReactNode>(
  value: boolean,
  onSome: () => R,
  onNone: () => R | null = constNull,
): R | null {
  return pipe(value, B.fold(onNone, onSome));
}

export function renderHttpResult<T, E, R extends ReactNode>(
  res: HttpResult<T, E>,
  onRight: (data: T) => R,
  onLeft: (err: HttpError<E>) => R | JSX.Element = error => <FullError error={error} />,
): R | JSX.Element | null {
  return pipe(res, EI.fold(onLeft, onRight));
}
