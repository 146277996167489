import React, { ChangeEventHandler, FC, FormEventHandler, useState } from 'react';

import * as Styled from './SearchInput.styles';
import { navigateTo } from '@shared/utils/routes';
import { useTranslations } from '@shared/modules/translation/hooks';

interface SearchInputProps {
  className?: string;
}

const SearchInput: FC<SearchInputProps> = ({ className }) => {
  const translations = useTranslations();

  const [search, setSearch] = useState<string>('');

  const handleSearch: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    e.stopPropagation();

    if (search) {
      navigateTo('search', undefined, { query: search });
    }
  };

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;

    setSearch(value);
  };

  return (
    <Styled.SearchInputContainer className={className} noValidate onSubmit={handleSearch}>
      <Styled.SearchInput
        type="text"
        value={search}
        placeholder={translations.search.placeholder}
        onChange={handleSearchChange}
      />
    </Styled.SearchInputContainer>
  );
};

export default SearchInput;
