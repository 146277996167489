export const locales = ['fr', 'en'] as const;

export type Locale = typeof locales[number];

export const LOCALE_COOKIE_NAME = 'i18next';

export interface InternationalizeString {
  french: string;
  english?: string | null;
}

export interface InternationalizeStringOpt {
  french?: string | null;
  english?: string | null;
}

export enum Language {
  French = 'french',
  English = 'english',
}
