import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';

import Header from '@layout/header/Header';

import * as Styled from './Layout.styles';
import { LayoutPrismicContent } from '@layout/model';
import Footer from '@layout/footer/Footer';
import PageLoader from '@layout/loader/PageLoader';

export const LAYOUT_CONTENT_ID = 'main';

export interface LayoutProps {
  layout: LayoutPrismicContent;
  headerLight?: boolean;
  hideFooter?: boolean;
}

const LayoutContext = createContext<LayoutProps>({} as LayoutProps);

const Layout: FC<PropsWithChildren<LayoutProps>> = ({ layout, headerLight, hideFooter = false, children }) => {
  const [props, setProps] = useState<LayoutProps>(() => ({
    layout,
    headerLight,
    hideFooter,
  }));

  useEffect(() => {
    setProps({ layout, headerLight, hideFooter });
  }, [layout, headerLight, hideFooter]);

  return (
    <LayoutContext.Provider value={props}>
      <PageLoader />

      <Header links={layout.links} headerLight={headerLight} />

      <Styled.LayoutContent id={LAYOUT_CONTENT_ID}>{children}</Styled.LayoutContent>

      {!hideFooter ? (
        <Footer
          logos={layout.firstLogos}
          secondTitle={layout.secondTitle}
          secondLinks={layout.secondLinks}
          thirdTitle={layout.thirdTitle}
          thirdLinks={layout.thirdLinks}
          fourthTitle={layout.fourthTitle}
          fourthLinks={layout.fourthLinks}
          privacyPolicyLinkLabel={layout.privacyPolicyLinkLabel}
          privacyPolicyLink={layout.privacyPolicyLink}
          cguLinkLabel={layout.cguLinkLabel}
          cguLink={layout.cguLink}
          youtube={layout.youtube}
          instagram={layout.instagram}
          twitter={layout.twitter}
          linkedin={layout.linkedin}
        />
      ) : null}
    </LayoutContext.Provider>
  );
};

export default Layout;

export function useLayoutContext() {
  return useContext(LayoutContext);
}
