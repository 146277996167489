import styled from 'styled-components';
import { fontSize, fontWeight, tertiaryColor } from '@styles/utils';
import backgroundImage from '@assets/icons/error/background.svg';
import { Button } from '@styles/shared/buttons';

export const FullErrorContainer = styled.div<{ $is404: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  min-height: 100vh;
  padding: 100px 20px;
  text-align: center;
  background: url(${backgroundImage.src}) no-repeat center;
  background-size: cover;

  > h1 {
    padding: 30px 0 10px;
  }
`;

export const FullErrorTitle = styled.p`
  padding-bottom: 10px;
  ${fontSize.h1};
  font-weight: ${fontWeight.bold};
  color: ${tertiaryColor(700)};
  line-height: 1.24;
`;

export const FullErrorDescription = styled.p`
  ${fontSize.standard};
  color: ${tertiaryColor(700)};
`;

export const FullErrorButtonContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FullErrorButton = styled(Button)`
  width: max-content;
`;
