import React, { FC } from 'react';
import * as Styled from './Footer.styles';
import { GroupField } from '@prismicio/types';
import { LayoutPrismicContentLinks, LayoutPrismicContentLogos } from '@layout/model';
import * as prismicT from '@prismicio/types';
import { getLinkPropsFromPrismicLink } from '@core/prismic';
import Image from '@core/prismic/components/Image';
import SocialNetworks from '@layout/footer/social-networks/SocialNetworks';
import { isFilled } from '@prismicio/helpers';

interface FooterProps {
  logos: GroupField<LayoutPrismicContentLogos>;
  secondTitle: prismicT.KeyTextField;
  secondLinks: GroupField<LayoutPrismicContentLinks>;
  thirdTitle: prismicT.KeyTextField;
  thirdLinks: GroupField<LayoutPrismicContentLinks>;
  fourthTitle: prismicT.KeyTextField;
  fourthLinks: GroupField<LayoutPrismicContentLinks>;
  privacyPolicyLinkLabel: prismicT.KeyTextField;
  privacyPolicyLink: prismicT.LinkField;
  cguLinkLabel: prismicT.KeyTextField;
  cguLink: prismicT.LinkField;
  youtube: prismicT.LinkField;
  instagram: prismicT.LinkField;
  twitter: prismicT.LinkField;
  linkedin: prismicT.LinkField;
}

const Footer: FC<FooterProps> = ({
  logos,
  secondTitle,
  secondLinks,
  thirdTitle,
  thirdLinks,
  fourthTitle,
  fourthLinks,
  privacyPolicyLinkLabel,
  privacyPolicyLink,
  cguLinkLabel,
  cguLink,
  youtube,
  instagram,
  twitter,
  linkedin,
}) => {
  return (
    <Styled.FooterWrapper>
      <Styled.FooterContainer>
        <Styled.FooterTop>
          <Styled.FooterTopItem>
            {logos.map((logo, index) =>
              isFilled.image(logo.logo) ? (
                <Styled.FooterTopItemLogo key={index}>
                  <Image image={logo.logo} />
                </Styled.FooterTopItemLogo>
              ) : null,
            )}
          </Styled.FooterTopItem>

          <Styled.FooterTopItem>
            <Styled.FooterTopItemTitle>{secondTitle}</Styled.FooterTopItemTitle>

            {secondLinks.map((link, i) => (
              <Styled.FooterTopItemLink {...getLinkPropsFromPrismicLink(link.link)} key={i}>
                {link.label}
              </Styled.FooterTopItemLink>
            ))}
          </Styled.FooterTopItem>

          <Styled.FooterTopItem>
            <Styled.FooterTopItemTitle>{thirdTitle}</Styled.FooterTopItemTitle>
            {thirdLinks.map((link, i) => (
              <Styled.FooterTopItemLink {...getLinkPropsFromPrismicLink(link.link)} key={i}>
                {link.label}
              </Styled.FooterTopItemLink>
            ))}
          </Styled.FooterTopItem>

          <Styled.FooterTopItem>
            <Styled.FooterTopItemTitle>{fourthTitle}</Styled.FooterTopItemTitle>

            {fourthLinks.map((link, i) => (
              <Styled.FooterTopItemLink {...getLinkPropsFromPrismicLink(link.link)} key={i}>
                {link.label}
              </Styled.FooterTopItemLink>
            ))}
          </Styled.FooterTopItem>
        </Styled.FooterTop>

        <Styled.FooterBottom>
          <Styled.FooterBottomLeft>
            <Styled.FooterBottomLeftLabel>© 2022 DE SANGOSSE</Styled.FooterBottomLeftLabel>

            <Styled.FooterBottomLeftLink {...getLinkPropsFromPrismicLink(privacyPolicyLink)}>
              {privacyPolicyLinkLabel}
            </Styled.FooterBottomLeftLink>

            <Styled.FooterBottomLeftLink {...getLinkPropsFromPrismicLink(cguLink)}>
              {cguLinkLabel}
            </Styled.FooterBottomLeftLink>
          </Styled.FooterBottomLeft>

          <SocialNetworks youtube={youtube} instagram={instagram} twitter={twitter} linkedin={linkedin} />
        </Styled.FooterBottom>
      </Styled.FooterContainer>
    </Styled.FooterWrapper>
  );
};

export default Footer;
