import React, { ChangeEventHandler, FC, FormEventHandler, useEffect, useState } from 'react';

import * as Styled from './NavSearch.styles';
import { navigateTo } from '@shared/utils/routes';
import { useRouter } from 'next/router';
import { getStringQuery } from '@shared/utils/queries';
import * as O from 'fp-ts/Option';

interface NavSearchProps {
  transparent: boolean;
}

const NavSearch: FC<NavSearchProps> = ({ transparent }) => {
  const router = useRouter();

  const [search, setSearch] = useState<string>('');

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const query = O.toNullable(getStringQuery(router.query, 'query'));

    setSearch(query ?? '');
    setOpen(!!query);
  }, [router.query]);

  const handleSearch: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    e.stopPropagation();

    if (search) {
      navigateTo('search', undefined, { query: search });
    }
  };

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;

    setSearch(value);
  };

  const handleFocus = () => {
    setOpen(true);
  };

  const handleBlur = () => {
    if (!search) {
      setOpen(false);
    }
  };

  const handleClose = () => {
    setSearch('');
    setOpen(false);

    if (router.pathname === '/search') {
      navigateTo('home');
    }
  };

  return (
    <Styled.NavSearchContainer noValidate onSubmit={handleSearch}>
      <Styled.NavSearchInput
        $open={open}
        $transparent={transparent}
        type="text"
        value={search}
        onChange={handleSearchChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      <Styled.NavSearchClearButton type="button" $open={open} $transparent={transparent} onClick={handleClose} />
    </Styled.NavSearchContainer>
  );
};

export default NavSearch;
