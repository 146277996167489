import styled from 'styled-components';
import { mediaMax, tertiaryColor } from '@styles/utils';
import CustomLink from '@shared/components/link/CustomLink';

export const HeaderContainer = styled.header<{ $transparent: boolean }>`
  position: fixed;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: ${props => (props.$transparent ? 'transparent' : tertiaryColor(100))};
  border-bottom: solid 1px ${props => (props.$transparent ? 'transparent' : 'rgba(112, 112, 112, 0.25)')};
  z-index: 100;
  transition: background-color 0.1s linear;

  @media screen and (${mediaMax.tablet}) {
    position: fixed;
  }
`;

export const HeaderContent = styled.div`
  padding: 0 50px 0 70px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  height: 100%;

  @media screen and (${mediaMax.tablet}) {
    padding: 0 20px;
    justify-content: space-between;
  }
`;

export const HeaderLogo = styled(CustomLink)`
  margin-right: 65px;
  display: inline-flex;
  align-items: flex-start;
  height: 100%;

  img {
    height: 100%;
  }
`;
